<template>
	<div class="message-channel-container" v-loading="loading">
		<div class="message-container-sider">
			<channel
				:channelTotal="channelTotal"
				:channels="channels"
				@replace="replace"
				@search="search"
				@loadMoreChannel="getChannel"
			/>
		</div>

		<div class="message-container-main">
			<message
				:class_name="class_name"
				:messages="messages"
				:messageTotal="messageTotal"
				@sendMessage="sendMessage"
				@loadMoreMessage="getMessage"
			/>
		</div>
	</div>
</template>

<script>
import channel from './channel'
import message from './message'
import { mapActions } from 'vuex'

export default {
	data: () => ({
		loading: false,

		class_name: '',
		chat_room_id: 0,

		messages: [],
		messageTotal: 0,

		channels: [],
		channelTotal: 0,

		searchChannelType: 'to_user_name',
		searchChannelText: '',

		channelLimit: 1,
		messageLimit: 1
	}),

	components: { channel, message },

	methods: {
		...mapActions('message', ['getChannelList', 'getChannelDetail']),

		async getChannel(flag = false) {
			this.loading = true

			const {
				data: { list, total }
			} = await this.getChannelList({
				[this.searchChannelType]: this.searchChannelText
			})

			this.channelLimit += 1

			this.loading = false

			this.channelTotal = total
			this.channels = flag ? [] : list
		},

		async getMessage(flag = true) {
			this.loading = true

			const {
				data: { list, total }
			} = await this.getChannelDetail({ chat_room_id: this.chat_room_id })

			this.messageLimit += 1

			this.loading = false

			this.messageTotal = total
			this.messages = flag ? [...this.messages, ...list] : list
		},

		async sendMessage(message) {
			console.log(message)
		},

		replace({ chat_room_id, class_name }) {
			this.chat_room_id = chat_room_id
			this.class_name = class_name
			this.messageLimit = 1

			this.getMessage(false)
		},

		search({ selectType, searchText }) {
			this.searchChannelText = searchText
			this.searchChannelType = selectType

			this.getChannel()
		}
	},

	created() {
		this.getChannel()
	}
}
</script>

<style scoped>
.message-channel-container {
	border-radius: 12px;
	position: relative;
	display: grid;
	grid-template-columns: 30% 70%;

	box-sizing: border-box;

	padding: 20px;
}
</style>
