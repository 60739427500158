<template>
	<div class="channel-container">
		<div style="padding: 20px 30px; border-bottom: 1px solid #dcdfe6">
			<span style="font-weight: bold; font-size: 18px">聊天列表</span>
			&nbsp;&nbsp;

			<span>一共{{ channelTotal }}个聊天</span>&nbsp;&nbsp;

			<el-button
				v-if="channelTotal > channels.length"
				type="primary"
				size="mini"
				@click="$emit('loadMoreChannel')"
			>
				加载更多
			</el-button>
		</div>

		<div
			style="
				padding: 20px 30px;
				border-bottom: 1px solid #dcdfe6;
				display: flex;
				justify-content: space-between;
			"
		>
			<el-select v-model="selectType" style="width: 120px" clearable>
				<el-option label="用户id" value="to_user_id" />
				<el-option label="用户昵称" value="name" />
				<el-option label="班级名称" value="class_name" />
			</el-select>
			<el-input clearable style="width: 120px" v-model="searchText" />
			<el-button
				size="mini"
				type="primary"
				@click="$emit('search', { selectType, searchText })"
				>筛选</el-button
			>
		</div>

		<div
			@click="$emit('replace', channel)"
			v-for="channel of channels"
			:key="channel.id"
			class="channel-item"
		>
			<el-avatar :src="channel.to_user_name_avatar_url" />

			<div class="channel-info">
				<div>
					<span style="font-weight: bold">{{ channel.class_name }}</span>
					&nbsp;&nbsp;&nbsp;&nbsp;
					<span style="font-size: 12px">{{ channel.created_at }}</span>
				</div>
				<div>{{ channel.content }}</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			selectType: 'to_user_id',
			searchText: ''
		}
	},

	props: {
		channels: {
			default: () => []
		},

		channelTotal: {
			default: 0
		}
	}
}
</script>

<style scoped>
.channel-container {
	border-radius: 12px;
	background-color: #eee;
	height: 1180px;
}

.channel-item {
	box-sizing: border-box;
	border-radius: 12px;
	cursor: pointer;
	transition: all 0.3s;
	display: flex;
	align-items: center;
	padding: 10px 30px;
	width: 100%;
}

.channel-item:hover {
	background-color: #999;
}

.channel-info {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.channel-info div {
	margin: 10px 15px;
}
</style>
