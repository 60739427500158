<template>
	<div class="message-container">
		<div class="message-box">
			<div
				style="
					border-bottom: 1px solid #eee;
					padding: 20px 30px;
					position: sticky;
					top: 0;
					background-color: #fff;
				"
			>
				<span style="font-weight: bold; font-size: 18px">
					消息详情 班级: {{ class_name }}
				</span>

				<template v-if="messageTotal">
					<span>当前没有消息</span>
				</template>

				<template v-else>
					<span>一共{{ messageTotal }}条消息</span>&nbsp;

					<el-button
						type="primary"
						size="mini"
						v-if="messageTotal > messages.length"
						@click="$emit('loadMoreMessage')"
					>
						加载更多
					</el-button>
				</template>
			</div>

			<div
				v-for="msg of messages"
				:key="msg.id"
				:class="[
					'message-item-content',
					{ self: msg.user_id == $store.state.user.info.id }
				]"
			>
				<div class="message-avatar">
					<el-avatar :src="msg.user_name_avatar_url" />
				</div>

				<div class="message-detail">
					<div
						class="message-nickname"
						style="font-size: 12px; font-weight: bold"
					>
						{{ msg.user_name }} :
					</div>
					<div class="message-content">
						{{ msg.content }}
					</div>
					<div class="message-date">{{ msg.created_at }}</div>
				</div>
			</div>
		</div>

		<div class="send-message" v-if="class_name || messageTotal">
			<el-input
				autofocus
				style="width: 1000px"
				placeholder="按下回车键发送"
				@keyup.enter.native="sendMessage"
				v-model="inputContent"
			/>&nbsp;&nbsp;&nbsp;&nbsp;

			<el-button size="small" type="primary" @click="sendMessage">
				发送
			</el-button>
		</div>
	</div>
</template>

<script>
import { config } from '@/js/config'

export default {
	data: () => ({
		imageUrl: config.imageUrl,
		inputContent: ''
	}),

	methods: {
		sendMessage() {
			this.$emit('sendMessage', this.inputContent)
			this.inputContent = ''
		}
	},

	props: {
		messages: {
			type: Array,
			default: () => []
		},

		messageTotal: {
			default: () => 0
		},

		class_name: {
			default: ''
		}
	}
}
</script>

<style scoped>
.message-container {
	letter-spacing: 1px;
	padding-left: 20px;
	border-radius: 12px;
}

.message-avatar {
	margin: 0 12px;
}

.message-content {
	text-align: justify;
	line-height: 2;
	max-width: 400px;
	font-size: 14px;
	padding: 8px 16px;
	margin: 8px 0;
	background-color: #eee;
	border-radius: 16px;
}

.message-item-content.self {
	flex-direction: row-reverse;
}

.message-date {
	font-size: 12px;

	text-align: right;
	color: #606266;
}

.message-item-content.self .message-content {
	background-color: #409eff;
	color: #fff;
}

.message-item-content {
	width: 100%;
	margin: 16px 0 24px 0;
	display: flex;
	align-items: center;
}

.send-message {
	position: absolute;
	bottom: 20px;
	display: flex;
	align-items: center;
}

.message-box {
	max-height: 1150px;
	margin-bottom: 24px;
	overflow-y: auto;
}
</style>
